<template>
  <div class="calcu-body buyness-calcu">
    <van-form class="form-bg">
      <!-- 选择城市 -->
      <van-field
        readonly
        clickable
        v-model="locationCity"
        label="选择城市"
        placeholder="点击选择城市"
        @focus="linkLocation"
        @click-right-icon="linkLocation"
        @click-left-icon="handleLocation"
      >
        <template #left-icon>
          <img
            src="../../assets/image/help.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <!-- 计算方式 -->
      <van-field
        name="radio"
        label="计算方式"
        class="cal-method"
      >
        <template #input>
          <van-radio-group 
            v-model="formData.methodType"
            direction="horizontal"
            @change="calcuMethodChange"
          >
            <van-radio name="1">房屋总价</van-radio>
            <van-radio name="2">贷款总额</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <!-- 房屋总价 -->
      <van-field
        v-model="formData.houseTotal"
        type="number"
        name="房屋总价"
        label="房屋总价"
        placeholder="请输入房屋总价"
        autocomplete="off"
        v-if="formData.calcuMethod === '按房屋总价'"
        @click="houseKeyboardShow = true"
        @blur="houseKeyboardShow = false"
        @focus="houseTotalFocus"
      >
        <template #extra>
          <span class="suffix">万</span>
        </template>
      </van-field>
      <!-- 房屋总价的数字键盘 -->
      <van-number-keyboard
        :show="houseKeyboardShow"
        v-model="formData.houseTotal"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        @close="houseKeyboardClose"
        @blur="houseKeyboardClose"
        style="z-index: 3000"
      />

      <!-- 首付比例 -->
      <van-field
        name="radio"
        label="首付比例"
        v-if="formData.calcuMethod === '按房屋总价'"
        @click-left-icon="handleownPayment"
        @click-right-icon="showDownPaymentPicker = true"
      >
        <template #input>
          <van-radio-group
            v-model="formData.isFirst"
            direction="horizontal"
            @change="isFirstChange"
          >
            <van-radio name="1">首套</van-radio>
            <van-radio name="2">二套</van-radio>
            <span
              v-if="formData.isFirst === '1' && !selectedPayment"
              class="down-payment-per"
              @click="showDownPaymentPicker = true"
              >{{ firstDefault }}</span
            >
            <span
              v-if="formData.isFirst === '2' && !selectedPayment"
              class="down-payment-per"
              @click="showDownPaymentPicker = true"
              >{{ secondDefault }}</span
            >
            <span
              v-if="formData.isFirst && selectedPayment"
              class="down-payment-per"
              @click="showDownPaymentPicker = true"
              >{{ formData.downPayment }}</span
            >
          </van-radio-group>
        </template>
        <template #left-icon>
          <img
            src="../../assets/image/help.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          title="首付比例" 
          :pickList="downPaymentList" 
          typeKey="downPayment" 
          v-if="showDownPaymentPicker" 
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入百分比" v-model="formData.customDownPayment">
              <button class="customer-btn" @click="handleCustomDownPayment">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>

      <!-- 货款总额 -->
      <van-field
        type="number"
        v-model="formData.total"
        name="贷款总额"
        label="贷款总额"
        placeholder="请输入贷款总额"
        autocomplete="off"
        @click="totalkeyboardShow = true"
        @blur="totalkeyboardShow = false"
        @focus="totalFocus"
      >
        <template #extra>
          <span class="suffix">万</span>
        </template>
      </van-field>
      <!-- 货款总额-数字键盘 -->
      <van-number-keyboard
        :show="totalkeyboardShow"
        v-model="formData.total"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        @close="totalKeyboardClose"
        @blur="totalKeyboardClose"
        style="z-index: 3000"
      />

      <!-- 公积金总额 -->
      <van-field
        v-model="formData.acctotal"
        type="number"
        name="公积金总额"
        label="公积金总额"
        placeholder="请输入公积金总额"
        autocomplete="off"
        @click="acctotalKeyboardShow = true"
        @blur="acctotalKeyboardShow = false"
        @focus="acctotalFocus"
      >
        <template #extra>
          <span class="suffix">万</span>
        </template>
      </van-field>
      <!-- 公积金总额-数字键盘 -->
      <van-number-keyboard
        :show="acctotalKeyboardShow"
        v-model="formData.acctotal"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        @close="acctotalKeyboardClose"
        @blur="acctotalKeyboardClose"
        style="z-index: 3000"
      />

      <!-- 贷款年限 -->
      <van-field
        readonly
        clickable
        v-model="formData.accLoanTerm"
        label="贷款年限"
        placeholder="点击选择贷款年限"
        @focus="showAccLoanTermPicker = true"
        @click-right-icon="showAccLoanTermPicker = true"
      >
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          title="贷款年限" 
          :pickList="loanTermList" 
          typeKey="accloanTerm" 
          v-if="showAccLoanTermPicker" 
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入贷款年限" v-model="formData.customAccTerm">
              <button class="customer-btn" @click="handleAccCustomTerm">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>

      <!-- 公积金利率 -->
      <van-field
        readonly
        clickable
        v-model="formData.accrate"
        label="公积金利率"
        placeholder="点击选择利率方式"
        @focus="showAccRatePicker = true"
        @click-left-icon="handleRateHelp"
        @click-right-icon="showAccRatePicker = true"
        class="left-195"
      >
        <template #left-icon>
          <img
            src="../../assets/image/help.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          title="公积金利率" 
          :pickList="accRateList" 
          typeKey="accRate" 
          v-if="showAccRatePicker" 
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入公积金利率" v-model="formData.accCustomRate">
              <button class="customer-btn" @click="handleAccCustomRate">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>

      <!-- 商业贷款 -->
      <van-field
        v-model="formData.businessLoan"
        type="number"
        name="商业贷款"
        label="商业贷款"
        placeholder="请输入商业贷款"
        autocomplete="off"
        @click="businessLoanKeyboardShow = true"
        @blur="businessLoanKeyboardShow = false"
        @focus="businessLoanFocus"
      >
        <template #extra>
          <span class="suffix">万</span>
        </template>
      </van-field>
      <!-- 商业贷款-数字键盘 -->
      <van-number-keyboard
        :show="businessLoanKeyboardShow"
        v-model="formData.businessLoan"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        @close="businessLoanKeyboardClose"
        @blur="businessLoanKeyboardClose"
        style="z-index: 3000"
      />

      <!-- 商业贷款-贷款年限 -->
      <van-field
        readonly
        clickable
        v-model="formData.loanTerm"
        label="贷款年限"
        placeholder="点击选择贷款年限"
        @focus="showLoanTermPicker = true"
        @click-right-icon="showLoanTermPicker = true"
      >
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>

      <keep-alive>
        <Picker 
          title="贷款年限" 
          :pickList="loanTermList" 
          typeKey="loanTerm" 
          v-if="showLoanTermPicker" 
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入贷款年限" v-model="formData.customTerm">
              <button class="customer-btn" @click="handleCustomTerm">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>

      <!-- 利率方式 -->
      <van-field
        readonly
        clickable
        v-model="formData.interestRateMethod"
        label="利率方式"
        placeholder="点击选择利率方式"
        @focus="showInterestRatePicker = true"
        @click-right-icon="showInterestRatePicker = true"
      >
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          title="利率方式" 
          :pickList="interestRateMethodList" 
          typeKey="interestRate" 
          v-if="showInterestRatePicker" 
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        ></Picker>
      </keep-alive>

      <!-- 贷款利率 -->
      <van-field
        readonly
        clickable
        v-model="formData.rate"
        label="贷款利率"
        placeholder="点击选择利率方式"
        @focus="showRatePicker = true"
        @click-left-icon="handleRateHelp"
        @click-right-icon="showRatePicker = true"
      >
        <template #left-icon>
          <img
            src="../../assets/image/help.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          :title="formData.interestRateMethod === '本月LPR'?'旧版基准利率':'旧版基准利率'" 
          :pickList="formData.interestRateMethod === '按LPR'?rateList:rateBaseList"
          typeKey="rate" 
          v-if="showRatePicker" 
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入百分比 %" v-model="formData.customRate">
              <button class="customer-btn" @click="handleCustomRate">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>

      <div style="margin: 16px">
        <van-button class="submit-btn" @click="onSubmit">开始计算</van-button>
      </div>
    </van-form>

    <!-- 模态框 -->
    <van-dialog
      v-model="customModalShow"
      show-cancel-button
      confirm-button-color="#FFCC33"
      @confirm="modalConfirm"
      @cancel="modalCancel"
    >
      <template #default>
        <div class="custom-dialog-content">
          目前贷款年限最大{{ $store.state.location.cityId == 1 ? 25 : 30 }}年，您确定查看吗？
        </div>
      </template>
    </van-dialog>
  </div>
</template>

<script>
import $http from '../../utils/request'
import { mapState } from "vuex";
import { totalNumValidate } from '../../validate/index';
import Picker from "../../components/Picker";

export default {
  components: {
    Picker
  },
  data() {
    return {
      showMehodsPicker: false,
      methodsList: [
        { text: "按房屋总价", value: 1 },
        { text: "按贷款总额", value: 2 },
      ],
      showLoanTermPicker: false,
      // loanTermList: ["5年", "10年", "15年", "20年", "25年", "30年"],
      showAccLoanTermPicker: false,

      showInterestRatePicker: false,
      interestRateMethodList: ["按LPR", "旧版基准利率"],
      showRatePicker: false,

      showAccRatePicker: false,
      showDownPaymentPicker: false,
      downPaymentList: [
        "20%",
        "25%",
        "30%",
        "35%",
        "40%",
        "45%",
        "50%",
        "60%",
        "70%",
        "80%",
      ],
      formData: {
        total: "0",
        acctotal: "0",
        calcuMethod: this.$route.query.cal || "按贷款总额",
        loanTerm: "25年",
        accLoanTerm: "25年",
        customTerm: "",
        customAccTerm: "",
        interestRateMethod: "按LPR",
        rate: "",
        accrate: "",
        customRate: "",
        accCustomRate: "",
        houseTotal: "0",
        isFirst: "1",
        methodType: "2",
        downPayment: "30%",
        customDownPayment: "",
        businessLoan: "0",
      },
      customModalShow: false,
      selectedPayment: false,
      currentModal: "",
      totalkeyboardShow: false,
      acctotalKeyboardShow: false,
      businessLoanKeyboardShow: false,
      houseKeyboardShow: false,
      customDownPaymentKeyboardShow: false,
      customAccTermKeyboardShow: false,
      accCustomRateKeyboardShow: false,
      customTermkeyboardShow: false,
      customRatekeyboardShow: false,
    };
  },
  watch: {
    rateList: {
      handler(newVal) {
        if (newVal && this.formData.interestRateMethod === "按LPR") {
          this.formData.rate = newVal[0].text;
        }
      },
      immediate: true,
    },
    rateBaseList: {
      handler(newVal) {
        if (newVal && this.formData.interestRateMethod === "旧版基准利率") {
          this.formData.rate = newVal[0].text;
        }
      },
      immediate: true,
    },
    accRateList: {
      handler(newVal) {
        if (newVal && Array.isArray(newVal)) {
          this.formData.accrate = newVal[0].text;
        }
      },
      immediate: true,
    },
  },
  methods: {
    pickerClose(typeKey) {
      switch(typeKey) {
        case "downPayment":
          this.showDownPaymentPicker = false;
          break;
        case "accloanTerm":
          this.showAccLoanTermPicker = false;
          break;
        case "accRate":
          this.showAccRatePicker = false;
          break;
        case "loanTerm":
          this.showLoanTermPicker = false;
          break;
        case "interestRate":
          this.showInterestRatePicker = false;
          break;
        case "rate":
          this.showRatePicker = false;
          break;
      }
    },
    pickerHandle(isShow, selected, typeKey) {
      console.log(isShow, selected, typeKey);
      switch(typeKey) {
        case "downPayment":
          this.onDownPaymentComplete(selected);
          break;
        case "accloanTerm":
          this.onAccLoanTermCancel(selected);
          break;
        case "accRate":
          this.onAccRateComplete(selected);
          break;
        case "loanTerm":
          this.onLoanTermComplete(selected);
          break;
        case "interestRate":
          this.onInterestRateComplete(selected);
          break;
        case "rate":
          this.onRateCancel(selected);
          break;
      }
      
    },
    customRateKeyboardClose() {
      this.customRatekeyboardShow = false;
    },
    customTermKeyboardClose() {
      this.customTermkeyboardShow = false;
    },
    accCustomRateKeyboardClose() {
      this.accCustomRateKeyboardShow = false;
    },
    customDownPaymentKeyboardClose() {
      this.customDownPaymentKeyboardShow = false;
    },
    customAccTermKeyboardClose() {
      this.customAccTermKeyboardShow = false;
    },
    // 贷款总额的获取焦点
    totalFocus(value) {
      // 如果贷款总额为0，获取焦点时置空。
      if(this.formData.total == 0) {
        this.formData.total = '';
      }
      document.activeElement.blur();
    },
    // 公积金总额获取焦点
    acctotalFocus() {
      if(this.formData.acctotal == 0) {
        this.formData.acctotal = '';
      }
      document.activeElement.blur();
    },
    // 商业贷款总额
    businessLoanFocus() {
      if(this.formData.businessLoan == 0) {
        this.formData.businessLoan = '';
      }
      document.activeElement.blur();
    },
    // 房屋总价获取焦点
    houseTotalFocus() {
      if(this.formData.houseTotal == 0) {
        this.formData.houseTotal = '';
      }
      document.activeElement.blur();
    },
    // 数字键盘的关闭
    totalKeyboardClose() {
      this.totalInput(this.formData.total);
      this.totalkeyboardShow = false;
    },
    acctotalKeyboardClose() {
      this.acctotalInput(this.formData.acctotal);
      this.acctotalKeyboardShow = false;
    },
    businessLoanKeyboardClose() {
      this.inputBusinessLoan(this.formData.businessLoan);
      this.businessLoanKeyboardShow = false;
    },
    houseKeyboardClose() {
      this.onHouseTotal(this.formData.houseTotal);
      this.houseKeyboardShow = false;
    },
    onSubmit() {
      // 开始计算的埋点
      window.gio('track', 'loanclick', {
        "module_var": "组合贷款",
        "buttonname_var": "开始计算",
        "userid_var": this.$store.state.userId
      });

      // TODO 表单提交
      if (this.formData.calcuMethod === "按贷款总额") {
        if (!Number(this.formData.total)) {
          this.$toast("贷款总额不能为空");
          return;
        }else if(!Number(this.formData.acctotal)) {
          this.$toast("公积金总额不能为空");
          return;
        }else if(!Number(this.formData.businessLoan)) {
          this.$toast("商业贷款不能为空");
          return;
        }
      } else if (this.formData.calcuMethod === "按房屋总价") {
        if (!Number(this.formData.houseTotal)) {
          this.$toast("房屋总价不能为空");
          return;
        } else if (!Number(this.formData.total)) {
          this.$toast("贷款总额不能为空");
          return;
        } else if(!Number(this.formData.acctotal)) {
          this.$toast("公积金总额不能为空");
          return;
        } else if(!Number(this.formData.businessLoan)) {
          this.$toast("商业贷款不能为空");
          return;
        }
      }

      let data = {};
      data.com_loan_time = parseInt(this.formData.loanTerm); // 商业贷款年限
      data.com_loan_value = Number(this.formData.businessLoan); // 商业贷款金额

      // 判断当前是不是自定义贷款利率，逻辑同商业贷款利率
      if(this.formData.customRate) {
        data.com_loan_rate = Number(this.formData.rate) / 100;
      }else {
        if(this.formData.interestRateMethod === '按LPR') {
          data.com_loan_rate = Number(this.rateList.find(item => item.text === this.formData.rate).value) / 100; // 商业贷款利率
        }else {
          data.com_loan_rate = Number(this.rateBaseList.find(item => item.text === this.formData.rate).value) / 100; // 商业贷款利率
        }
      }
      
      data.fund_loan_time = parseInt(this.formData.accLoanTerm); // 公积金贷款年限
      data.fund_loan_value = Number(this.formData.acctotal); // 公积金贷款金额
      // 判断当前是不是自定义公积金利率，逻辑同公积金利率
      if(this.formData.accCustomRate) {
        data.fund_loan_rate = Number(this.formData.accrate) / 100;
      }else {
        data.fund_loan_rate  = Number(this.accRateList.find(item => item.text === this.formData.accrate).value) / 100 ; // 公积金贷款利率
      }
      if(this.formData.calcuMethod === '按房屋总价') {
        data.is_first = this.formData.isFirst === '1' ? 1 : 0; // 是否首套：1首套|0非首套 默认1
        data.house_price = Number(this.formData.houseTotal); // 房屋总价 默认0
        data.payment_percent = parseFloat(document.getElementsByClassName('down-payment-per')[0].innerText) / 100; // 首付比例 默认0
      }else {
        data.is_first = '';
        data.house_price = '';
        data.payment_percent = '';
      }

      $http({
        url: '/appapi/normals/1/v1/loandetails',
        method: 'post',
        data
      }).then(res => {
        if(res.msg == 'OK') {
          this.$store.dispatch('setCalcuRes', res.data);
          // 在localStorage中做本地持久化
          localStorage.setItem('calcuRes', JSON.stringify(res.data));
          this.$router.push('/CommercialIoanDetailed?type=composition&cal=' + this.formData.calcuMethod)
        }
      })
    },
    linkLocation() {
      this.$router.push("/location");
    },
    handleLocation() {
      // 点击选择城市前的问号
      this.$store.commit("showLocationModal", true);
    },
    // 贷款总额的输入
    totalInput(value) {
      this.formData.total = totalNumValidate(value, this);
      this.formData.businessLoan = Number(this.formData.total) ? String(Number(this.formData.total)) : '';
    },
    // 公积金总额的输入
    acctotalInput(value) {
      if(!Number(this.formData.total)) {
        this.$toast(`贷款总额为0，请输入贷款总额。`);
        this.formData.acctotal = '0';
        return;
      }

      if (
        Number(this.formData.total) &&
        Number(value)
      ) {
        // 此处需要判断计算方式
        if (this.formData.calcuMethod === "按贷款总额") {
          if (Number(value) > this.firstMaxFundLoan) {
            this.formData.businessLoan = String(
              Number(this.formData.total) - this.firstMaxFundLoan
            );
            this.formData.acctotal = String(this.firstMaxFundLoan);
            this.$toast(
              `目前${this.locationCity}的公积金金额上限为${this.firstMaxFundLoan}万`
            );
          } else {
            this.formData.businessLoan = String(
              Number(this.formData.total) - Number(value)
            );
          }
        } else {
          // 定义一个公积金最大额度
          let MaxFundLoan = 0;
          // 按房屋总价 需要判断当前是首套还是二套
          if (this.formData.isFirst === "1") {
            // 当前为首套
            MaxFundLoan = this.firstMaxFundLoan;
          } else {
            // 当前为二套
            MaxFundLoan = this.secondMaxFundLoan;
          }
          // 获得公积金最大额度之后，执行和按贷款总额相同的逻辑
          if (Number(value) > MaxFundLoan) {
            this.formData.businessLoan = String(
              Number(this.formData.total) - MaxFundLoan
            );
            this.formData.acctotal = String(MaxFundLoan);
            this.$toast(
              `目前${this.locationCity}的公积金金额上限为${MaxFundLoan}万`
            );
          } else {
            this.formData.businessLoan = String(
              Number(this.formData.total) - Number(value)
            );
          }
        }

        // 公积金的总额大于贷款总额的情况
        if(Number(value) > Number(this.formData.total)) {
          this.formData.businessLoan = '0';
          this.formData.acctotal = this.formData.total;
        }
      }
    },
    // 商业贷款输入
    inputBusinessLoan(value) {
      if(!Number(this.formData.total)) {
        this.$toast(`贷款总额为0，请输入贷款总额。`);
        this.formData.businessLoan = '0';
        return;
      }

      this.formData.businessLoan = totalNumValidate(value, this)

      if (Number(this.formData.total) && Number(this.formData.businessLoan)) {
        if (Number(this.formData.businessLoan) > Number(this.formData.total)) {
          // 超过贷款总额 商业贷款取值为总贷款额 公积金贷款归零
          this.$toast("超过贷款总额");
          this.formData.businessLoan = String(this.formData.total);
          this.formData.acctotal = "0";
        } else {
          // 计算贷款总额与商业贷款
          const accDVal = String(Number(this.formData.total) - Number(value));
          // 判断差值与当前城市的公积金贷款上限，公积金的上限的取值，需要依据计算方式做区分
          /**
           * 按贷款总额：
           *    公积金上限则取当前城市首套公积金上限
           * 按房屋总价：
           *    公积金上限则需要按照首套或者二套的上限
           */
          if (this.formData.calcuMethod === "按贷款总额") {
            // 按照贷款总额
            this.formData.acctotal =
              accDVal > this.firstMaxFundLoan
                ? String(this.firstMaxFundLoan)
                : String(accDVal);
          } else {
            // 声明一个变量来存储当前的公积金最大额度
            let MaxFundLoan = 0;
            // 按照房屋总价  需要判断当前的
            if (this.formData.isFirst === "1") {
              MaxFundLoan = this.firstMaxFundLoan;
            } else {
              MaxFundLoan = this.secondMaxFundLoan;
            }
            this.formData.acctotal =
              accDVal > MaxFundLoan ? String(MaxFundLoan) : String(accDVal);
          }
        }
      }
    },
    onMethodComplete() {
      this.formData.calcuMethod = this.$refs.methodRef.getValues()[0].text;
      this.showMehodsPicker = false;
    },
    onLoanTermComplete(value) {
      this.formData.loanTerm = value;
      if (this.formData.interestRateMethod === "按LPR") {
        if (parseInt(this.formData.loanTerm) > 1) {
          this.formData.rate = this.com_rate_1["1"][0].text;
        } else {
          this.formData.rate = this.com_rate["1"][0].text;
        }
      }
      this.showLoanTermPicker = false;
    },
    onAccLoanTermCancel(value) {
      this.formData.accLoanTerm = value;
      if (parseInt(this.formData.accLoanTerm) > 5) {
        this.formData.accrate = this.fund_rate_5[0].text;
      } else {
        this.formData.accrate = this.fund_rate[0].text;
      }
      this.showAccLoanTermPicker = false;
    },
    onInterestRateComplete(value) {
      this.formData.interestRateMethod = value;
      if (this.formData.interestRateMethod === "按LPR") {
        this.formData.rate = this.rateList[0].text;
      } else {
        this.formData.rate = this.rateBaseList[0].text;
      }
      this.showInterestRatePicker = false;
    },
    handleCustomTerm() {
      // 自定义贷款年限
      const LOANTERM = parseInt(this.loanTermList[this.loanTermList.length - 1]);
      if (this.formData.customTerm <= LOANTERM) {
        this.showLoanTermPicker = false;
        this.formData.loanTerm = `${this.formData.customTerm}年`;
      } else {
        // 贷款年限大于30年小于等于60年，弹出提示框
        if (this.formData.customTerm > LOANTERM && this.formData.customTerm <= 60) {
          this.customModalShow = true;
          this.currentModal = "busy";
        } else {
          this.$toast("自定义最大输入年限为60年，请重新输入！");
        }
      }
    },
    handleAccCustomTerm() {
      const LOANTERM = parseInt(this.loanTermList[this.loanTermList.length - 1]);
      if (this.formData.customAccTerm) {
        if (this.formData.customAccTerm <= LOANTERM) {
          this.showAccLoanTermPicker = false;
          this.formData.accLoanTerm = `${this.formData.customAccTerm}年`;
        } else {
          // 贷款年限大于30年小于等于60年，弹出提示框
          if (
            this.formData.customAccTerm > LOANTERM &&
            this.formData.customAccTerm <= 60
          ) {
            this.customModalShow = true;
            // 设置当前的modal为公积金贷款年限
            this.currentModal = "acc";
          } else {
            this.$toast("自定义最大输入年限为60年，请重新输入！");
          }
        }
      } else {
        this.$toast("自定义贷款年限不能为空！");
      }
    },
    modalConfirm() {
      // TODO 模态框确定
      this.customModalShow = false;
      if (this.currentModal === "acc") {
        this.formData.accLoanTerm = `${this.formData.customAccTerm}年`;
        if (parseInt(this.formData.accLoanTerm) > 5) {
          this.formData.accrate = this.fund_rate_5[0].text;
        } else {
          this.formData.accrate = this.fund_rate[0].text;
        }
        this.showAccLoanTermPicker = false;
      } else if (this.currentModal === "busy") {
        this.formData.loanTerm = `${this.formData.customTerm}年`;
        // 按照商业贷款的利率方式设置贷款利率的默认值
        if (this.formData.interestRateMethod === "按LPR") {
          if (parseInt(this.formData.loanTerm) > 1) {
            this.formData.rate = this.com_rate_1["1"][0].text;
          } else {
            this.formData.rate = this.com_rate["1"][0].text;
          }
        }
        this.showLoanTermPicker = false;
      }
    },
    modalCancel() {
      // TODO 模态框取消
      this.formData.customTerm = "";
    },
    onRateCancel(item) {
      this.formData.rate = item.text;
      this.showRatePicker = false;
    },
    onAccRateComplete(item) {
      this.formData.accrate = item.text;
      this.showAccRatePicker = false;
    },
    accRateChange(picker, value, index) {
      this.formData.accrate = value.text;
    },
    handleRateHelp() {
      this.$store.commit("showRateModal", true);
    },
    handleownPayment() {
      this.$store.commit("showHouseModal", true);
    },
    handleCustomRate() {
      // const LPRreg = /^(\d{1,2})(\.{0}|\.\d{2})$/;
      const LPRreg = /(^[0-9]{1,2}$)|(^[0-9]{1,2}[\.]{1}[0-9]{1,2}$)/;
      const isLPRExit =
        LPRreg.test(this.formData.customRate) &&
        Number(this.formData.customRate) > 0;

      if (this.formData.customRate) {
        if (isLPRExit) {
          // 通过验证
          this.formData.rate = this.formData.customRate;
          this.showRatePicker = false;
          this.customRatekeyboardShow = false;
        } else {
          this.$toast("自定义输入范围为0.01%-99.99，支持小数点后两位！");
        }
      } else {
        this.$toast("自定义贷款利率不能为空");
      }
    },
    handleAccCustomRate() {
      // const LPRreg = /^(\d{1,2})(\.{0}|\.\d{2})$/;
      const LPRreg = /(^[0-9]{1,2}$)|(^[0-9]{1,2}[\.]{1}[0-9]{1,2}$)/;
      const isLPRExit =
        LPRreg.test(this.formData.accCustomRate) &&
        Number(this.formData.accCustomRate) > 0;

      if (this.formData.accCustomRate) {
        if (isLPRExit) {
          // 通过验证
          this.formData.accrate = this.formData.accCustomRate;
          this.showAccRatePicker = false;
        } else {
          this.$toast("自定义输入范围为0.01%-99.99，支持小数点后两位！");
        }
      } else {
        this.$toast("自定义贷款利率不能为空");
      }
    },
    onDownPaymentComplete(value) {
      // 首付比例
      this.formData.downPayment = value;
      this.selectedPayment = true;
      // 计算首付
      this.formData.businessLoan = this.formData.total = String(
        ((Number(this.formData.houseTotal) *
          (100 - parseInt(this.formData.downPayment))) /
          100).toFixed(2)
      );
      this.showDownPaymentPicker = false;
    },
    handleCustomDownPayment() {
      // 自定义首付比例
      if (Number(this.formData.customDownPayment)) {
        if (
          Number(this.formData.customDownPayment) > 100 ||
          Number(this.formData.customDownPayment) < 10
        ) {
          this.$toast("请输入10-100之间的数字");
        } else {
          this.formData.downPayment = `${this.formData.customDownPayment}%`;
          this.selectedPayment = true;

          this.formData.businessLoan = this.formData.total = String(
            ((Number(this.formData.houseTotal) *
              (100 - parseInt(this.formData.downPayment))) /
              100).toFixed(2)
          );

          this.showDownPaymentPicker = false;
        }
      } else {
        this.$toast("自定义首付比例不能为空！");
      }
    },
    onHouseTotal(value) {
      // 房屋总价的输入监听
      let defaultPercent = "";
      if (Number(value)) {
        const valueStr = String(parseInt(value));
        const dicStr = String(value).split(".")[1]
          ? String(value).split(".")[1]
          : "00";
        if (valueStr.length > 5) {
          this.$toast("请您输入小于五位数的数字");
          this.formData.houseTotal = valueStr.substr(0, 5) + "." + dicStr;
        } else {
          if (dicStr.length > 2) {
            this.$toast("最多支持两位小数");
            this.formData.houseTotal =
              valueStr.substr(0, 5) + "." + dicStr.substr(0, 2);
          }
        }
        if (this.formData.isFirst === "1") {
          defaultPercent = this.firstDefault;
        } else {
          defaultPercent = this.secondDefault;
        }
        this.formData.total = String(
          ((Number(this.formData.houseTotal) *
            (100 - parseInt(defaultPercent))) /
            100).toFixed(2)
        );
        this.formData.total = Number(this.formData.total).toFixed(2).toString();
        this.formData.businessLoan = String(
          ((Number(this.formData.houseTotal) *
            (100 - parseInt(defaultPercent))) /
            100).toFixed(2)
        );
        this.formData.businessLoan = Number(this.formData.businessLoan).toFixed(2).toString();
      } else {
        this.formData.total = "0";
      }
    },
    calcuMethodChange(name) {
      if(name === "1") {
        this.formData.calcuMethod = '按房屋总价';
      }else if(name === "2"){
        this.formData.calcuMethod = '按贷款总额';
      }
    },
    isFirstChange(name) {
      // 用户选择首套或者二套时，请求后台的首付比例
      this.selectedPayment = false;
      if (name === "1") {
        this.formData.total = this.formData.businessLoan = String(
          ((Number(this.formData.houseTotal) *
            (100 - parseInt(this.firstDefault))) /
            100).toFixed(2)
        );
      } else if (name === "2") {
        this.formData.total = this.formData.businessLoan = String(
          ((Number(this.formData.houseTotal) *
            (100 - parseInt(this.secondDefault))) /
            100).toFixed(2)
        );
      }
    },
  },
  computed: {
    defaultCal() {
      if (this.$route.query.cal) {
        if (this.$route.query.cal === "按贷款总额") {
          return 1;
        } else if (this.$route.query.cal === "按房屋总价") {
          return 0;
        }
      } else {
        return 1;
      }
    },
    firstDefault() {
      return this.$store.state.downPayment.firstDefault;
    },
    secondDefault() {
      return this.$store.state.downPayment.secondDefault;
    },
    locationCity() {
      return this.$store.state.location.cityName;
    },
    firstMaxFundLoan() {
      return this.$store.state.firstMaxFundLoan;
    },
    secondMaxFundLoan() {
      return this.$store.state.secondMaxFundLoan;
    },
    ...mapState({
      com_rate: (state) => {
        return state.com_rate;
      },
      com_rate_1: (state) => state.com_rate_1,
      fund_rate: (state) => {
        return state.fund_rate;
      },
      fund_rate_5: (state) => state.fund_rate_5,
    }),
    rateList() {
      // 商业贷款的贷款利率
      return parseInt(this.formData.loanTerm) > 1
        ? this.com_rate_1["1"]
        : this.com_rate["1"];
    },
    rateBaseList() {
      return parseInt(this.formData.loanTerm) > 1
        ? this.com_rate_1["2"]
        : this.com_rate["2"];
    },
    accRateList() {
      // 公积金贷款利率
      return parseInt(this.formData.accLoanTerm) > 5
        ? this.fund_rate_5
        : this.fund_rate;
    },
    loanTermList() {
      return this.$store.state.loanTime;
    }
  },
};
</script>

<style scoped>
.calcu-body {
  width: 686px;
  margin: 24px auto;
  border-radius: 16px;
  overflow: hidden;
}
.calcu-body /deep/ .van-cell {
  padding-top: 45px;
  height: 129px;
  box-sizing: border-box;
}
.calcu-body /deep/ .van-cell::after {
  border: 1px solid #F5F5F5;
}
.calcu-body /deep/ .van-field__left-icon {
  position: absolute;
  top: 45px;
  left: 165px;
}
.calcu-body /deep/ .left-195 .van-field__left-icon {
  left: 195px !important;
}
.calcu-body /deep/ .van-cell__title {
  color: #292B33;
  font-weight: bold;
  font-size: 32px;
}
.calcu-body /deep/ .van-field__body input,
.suffix {
  font-size: 32px;
  color: #333;
}
.cal-method /deep/ .van-radio__label {
  margin-left: 0;
}
.calcu-body /deep/ .van-radio-group .van-radio:last-child {
  margin-right: 0;
}
.calcu-body /deep/ .van-radio__icon .van-icon {
  width: 1em;
  height: 1em;
  line-height: 1;
}
.form-bg {
  background-color: #ffffff;
  padding-bottom: 25px;
}
.submit-btn {
  width: 622px;
  height: 96px;
  border-radius: 16px;
  line-height: 96px;
  background-color: #ffcc33;
  border: none;
  font-size: 28px;
  color: #333333;
  font-weight: 600;
}
.divide {
  width: 622px;
  margin: 30px auto;
}
.popup-title {
  width: 670px;
  height: 112px;
  border-bottom: 2px solid #eeeeee;
  line-height: 112px;
  font-size: 32px;
  color: #333;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popup-title .complete {
  color: #ffcc33;
}
.custom-term {
  height: 112px;
  width: 670px;
  margin: 0 auto;
  border-top: 2px solid #eeeeee;
}
.customer-btn {
  color: #333;
  border: none;
  outline: none;
}
.custom-dialog-content {
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 25px 48px 25px;
  padding-top: 80px;
  width: 568px;
  height: 218px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 45px;
  letter-spacing: 1px;
}
.down-payment-per {
  display: inline-block;
}
.margin-b-244 {
  margin-bottom: 488px;
}
</style>